import React from "react";
import { createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import Home from "./Components/Home";
import Customers from "./Components/Customers";
import Areas from "./Components/Areas";
import Jobs from "./Components/Jobs";

const router = createBrowserRouter(
    createRoutesFromElements(
    
      <Route path="/"  element={<Home/>}>
      <Route path="/customers" element={<Customers/>} />
      <Route path="areas" element={<Areas/>} />
      <Route path="jobs" element={<Jobs/>} />

      </Route>

    )
  );

  
  export default router