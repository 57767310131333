import React from "react";
import { useState, useEffect } from "react";
import urls from "./../Dirs/urls.js";
import styles from "./../Styles/menu.module.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Area from "./Area.js";
import { useMediaQuery } from "react-responsive";

const Customers = (props) => {
  const [areas, setAreas] = useState([]);
  const [currentArea, setCurrentArea] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 1223px)" });

  //retrieve access_token from cookies
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length == 2) return parts.pop().split(";").shift();
  };

  const accessToken = getCookie("access_token");

  useEffect(() => {
    const getAreas = async () => {
      const response = await axios.get(urls.areas, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setAreas(response.data);
      console.log(response.data);
    };
    getAreas();
  }, [currentArea]);

  console.log(currentArea);

  return (
    <div>
      <ul className={styles.areas}>
        {areas.map((area) => (
          <li
            onClick={() => {
              setCurrentArea(area.area);
            }}
            className={
              area.area == currentArea ? styles.currentArea : styles.area
            }
          >
            {area.area}
          </li>
        ))}
      </ul>
      {currentArea ? <Area area={currentArea} token={accessToken} /> : ""}
    </div>
  );
};

export default Customers;
