import  React from  'react';
import areasIcon from '/Users/filipsuwik/Desktop/Projekty/midlothianwindowscleaning/wcapp/src/Components/Svg/AreasSvg.svg';

const AreasIcon = () => {
    return (
        <>
            <img src={areasIcon} alt="areas icon" width="45vw" height="50vw" style={{cursor:"pointer"}} ></img>
        </>
    )
}

export default AreasIcon
