import  React from  'react';
import jobsIcon from '/Users/filipsuwik/Desktop/Projekty/midlothianwindowscleaning/wcapp/src/Components/Svg/JobsSvg.svg';

const JobsIcon = () => {
    return (
        <>
            <img src={jobsIcon} alt="jobs icon" width="50vw" height="45vw" style={{cursor:"pointer"}}></img>
        </>
    )
}

export default JobsIcon
