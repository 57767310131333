import React, { useState } from 'react';
import {RouterProvider} from "react-router-dom";
import router from "./Router";
import styles from './Styles/menu.module.css';
import LogInOut from './Components/LogInOut';






function App() {



 
 



   return (
    <div className={styles.wrapper}>
    <LogInOut />
    <RouterProvider router={router} />
    
    </div>
  );
}
export default App;

