import  React from  'react';
import homeIcon from '/Users/filipsuwik/Desktop/Projekty/midlothianwindowscleaning/wcapp/src/Components/Svg/HomeSvg.svg';

const HomeIcon = () => {
    return (
        <>
            <img src={homeIcon} alt="home icon" width="55vw" height="40vw" style={{cursor:"pointer"}}></img>
        </>
    )
}

export default HomeIcon
