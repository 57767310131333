import React from 'react';
import styles from './../Styles/topnavbar.module.css';


const LogInOut = () => {
    return (
        <div >
            <a className={styles.loginout} href="https://midlothian.auth.eu-west-2.amazoncognito.com/login?client_id=6744vpt0m19nkq7nvjng212mbj&response_type=token&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=https%3A%2F%2Fmidlothianwindowscleaning.co.uk">Login</a>

        </div>
    )
}

export default LogInOut