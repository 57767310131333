import React from "react";
import { useState, useEffect } from "react";
import urls from "./../Dirs/urls.js";
import styles from "./../Styles/menu.module.css";
import axios from "axios";

const Area = ({area,token}) => {


    const [areaCustomers, setAreaCustomers] = useState([]);
    const [markedCustomers, setMarkedCustomers] = useState([]);
    console.log(markedCustomers);

    let markCustomer = function(customer){
    
        setMarkedCustomers([...markedCustomers, customer]);
    }


    useEffect(() => {
        axios.get(urls.area + area, 
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                setAreaCustomers(res.data);
                console.log(res.data);
        
            }
        );

            
        },[area]
    );

    return (
        <div >
            <h3 style={{textAlign:'center'}}>{area} {areaCustomers.length} customers</h3>
            <ul className={styles.list}>
                {areaCustomers.map((customer) => (
                    <li className={styles.customer} onClick={() => markCustomer(customer)}>{customer.street} {customer.house_number} </li>
                ))}
            </ul>   
        </div>
    );


}


export default Area;
