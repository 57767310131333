import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './../Styles/clock.module.css';

const Clock = () => {

    const date = new Date();
    const currentDayTime = date.toLocaleString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'});

    const loc = useLocation().pathname;

    if (loc === '/') {

    return (

          
        <div className={styles.clock}>
            <h1>{currentDayTime}</h1>
      
        </div>
    )
    }
    else {return null}
}

export default Clock