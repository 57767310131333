import React from 'react';
import {Link, Outlet, } from "react-router-dom"
import { useState } from 'react';
import styles from './../Styles/menu.module.css';
import Clock from './Clock';
import HomeIcon from './HomeIcon';
import CustomersIcon from './CustomersIcon';
import AreasIcon from './AreasIcon';
import JobsIcon from './JobsIcon';
import  getAccessToken from "/Users/filipsuwik/Desktop/Projekty/midlothianwindowscleaning/wcapp/src/Helpers/getAccessToken.js";





const Home =(props) => {
    const accessToken = getAccessToken()

    //set token as cookie
    document.cookie = `access_token=${accessToken}; path=/;`



    return (
        <div className={styles.wrapper}>
         <div className={ styles.outlet} >
        <Outlet />
         </div>

         <Clock />

 
        <div className={styles.menu}>
        <Link to={'/'} className={styles.link} ><HomeIcon/></Link>
        <Link to={'customers'} className={styles.link} ><CustomersIcon/></Link>
        <Link to={'areas'} className={styles.link} ><AreasIcon style={{width:'40px',height:'40px'}}/></Link>
        <Link to={'jobs'} className={styles.link} ><JobsIcon/></Link>
        </div>
       
        </div>
    )
}


export default Home