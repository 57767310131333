import  React from  'react';
import customersIcon from '/Users/filipsuwik/Desktop/Projekty/midlothianwindowscleaning/wcapp/src/Components/Svg/CustomersSvg.svg';

const CustomersIcon = () => {
    return (
        <>
            <img src={customersIcon} alt="home icon" width="55vw" height="70vw" style={{cursor:"pointer"}}></img>
        </>
    )
}

export default CustomersIcon
