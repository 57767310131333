import React from "react";

const Jobs =() => {
        
        return (
            <div>
                <h1>Jobs</h1>
            </div>
        )
    }

export default Jobs